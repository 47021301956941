import React, { Component } from 'react';
import { Link } from 'react-router-dom';




export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }



    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg fixed-top navbar-transparent" color-on-scroll="50">
                <div className="container">
                    <div className="navbar-translate">

                        <Link to="/" className="navbar-brand" rel="tooltip" title="Qapla'• Fierce Software" data-placement="bottom" >
                            <img src="img/qp_white.png" alt="Qapla Logo" className="header-logo" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation"
                            aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                    </div>
                    <div className="collapse navbar-collapse" id="navigation">
                        <div className="navbar-collapse-header">
                            <div className="row">
                                <div className="col-10 collapse-brand" style={{ 'paddingLeft': 0 }}>
                                    <Link to="/" className="text-danger">Qapla'• <span> PRO Software </span></Link>
                                </div>
                                <div className="col-2 collapse-close">
                                    <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navigation"
                                        aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                                        <i className="tim-icons icon-simple-remove" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link to="/" className="nav-link">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="https://browserboard.com" target="_blank" className="nav-link">Browserboard</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact-us" className="nav-link">Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

import React, { Component } from 'react';
import { Routes, Route } from 'react-router';
import { Layout } from './components/Layout';
//import { Home } from './components/Home';
import { ContactUs } from './components/ContactUs';
import { Showcase } from './components/Showcase'
import { useLocation } from 'react-router-dom';

export default class App extends Component {
    static displayName = App.name;

    Child = () => {
        const location = useLocation();
        console.log(location)
    }

    render() {
        return (
            <Layout location={window.location}>
                <Routes>
                    <Route exact path='/' element={<Showcase/>} />
                    <Route path='/contact-us' element={<ContactUs/>} />
                </Routes>
            </Layout>
        );
    }
}

import React, { Component } from 'react';

export class ContactUs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isEmailSent: false
        };

        this.onFullNameChange = this.onFullNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onMessageChange = this.onMessageChange.bind(this);
        this.send = this.send.bind(this);

        this.state = {
            fullName: "",
            fullNameValidationClass: "",
            email: "",
            emailValidationClass: "",
        };
    }

    onFullNameChange(event) {

        if (event !== undefined) {
            this.setState({
                fullName: event.target.value
            });
        }

        const isValid = document.querySelector("#fullName").checkValidity();

        if (isValid) {
            this.setState({
                fullNameValidationClass: "has-success"
            });
        } else {
            this.setState({
                fullNameValidationClass: "has-danger"
            });
        }
    }

    onEmailChange(event) {

        if (event !== undefined) {
            this.setState({
                email: event.target.value
            });
        }

        const isValid = document.querySelector("#email").checkValidity();

        if (isValid) {
            this.setState({
                emailValidationClass: "has-success"
            });
        } else {
            this.setState({
                emailValidationClass: "has-danger"
            });
        }
    }

    onMessageChange(event) {

        if (event !== undefined) {
            this.setState({
                message: event.target.value
            });
        }

        const isValid = document.querySelector("#message").checkValidity();

        if (isValid) {
            this.setState({
                messageValidationClass: "has-success"
            });
        } else {
            this.setState({
                messageValidationClass: "has-danger"
            });
        }
    }

    async send(event) {
        event.preventDefault();

        this.onFullNameChange();
        this.onEmailChange();
        this.onMessageChange();

        if (document.querySelector("#contact-us-form").checkValidity()) {

            const url = '/api/contactus';

            const options = {
                method: 'POST',
                body: JSON.stringify({
                    name: this.state.name,
                    email: this.state.email,
                    message: this.state.message
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            let response = await fetch(url, options)
            if (response.ok) {
                this.setState({
                    isEmailSent: true
                });
            }
        }
    }

    onKeyDown = (event) => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.send(event);
        }
    }

    render() {

        return (

            <div className="register-page page-header">
                <div className="col-lg-5 col-md-12 mx-auto">
                    <div className="card card-register">
                        <div className="card-header">
                            <img className="card-img" src="../img/square1.png" alt="Card image" />
                            <h4 className="card-title">Contact</h4>
                            <h5 className="card-subtitle">Flick us a friendly message</h5>
                        </div>
                        <div className="card-body">
                            {!this.state.isEmailSent &&
                                <form id="contact-us-form" className="form" autoComplete="off" onSubmit={this.save}>
                                    <div className={`input-group ${this.state.fullNameValidationClass}`}>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="tim-icons icon-single-02"></i>
                                            </div>
                                        </div>
                                        <input id="fullName" type="text" className="form-control" placeholder="Full Name"
                                            required={true} onChange={this.onFullNameChange} onKeyDown={this.onKeyDown} />
                                    </div>
                                    <div className={`input-group ${this.state.emailValidationClass} `}>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="tim-icons icon-email-85"></i>
                                            </div>
                                        </div>
                                        <input id="email" type="email" placeholder="Email" className="form-control"
                                            required={true} onChange={this.onEmailChange} onKeyDown={this.onKeyDown} />
                                    </div>
                                    <div className={`input-group ${this.state.messageValidationClass} `}>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="tim-icons icon-lock-circle"></i>
                                            </div>
                                        </div>
                                        <textarea id="message" className="form-control" required={true}
                                            onChange={this.onMessageChange} onKeyDown={this.onKeyDown} placeholder="Message" />
                                    </div>
                                    <input type="submit" className="btn btn-info btn-round btn-lg" value="Send" />
                                </form>
                            }
                            {this.state.isEmailSent &&
                                <p className="mt-3">Thanks for your interest, we'll be in contact shortly.</p>
                            }
                        </div>
                        <div className="card-footer">

                        </div>
                    </div>
                </div>
                <div id="" className="square square-1" />
                <div id="" className="square square-2" />
                <div id="" className="square square-3" />
                <div id="" className="square square-4" />
                <div id="" className="square square-5" />
                <div id="" className="square square-6" />
            </div>
        );
    }
}

import React, { Component } from 'react';

export class Footer extends Component {
    
    render () {
        return (
            <>
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="title">Qapla'</h1>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}


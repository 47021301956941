import React, {Component} from 'react';
import {BackgroundImages} from "./BackgroundImages";

export class Showcase extends Component {
    static displayName = Showcase.name;

    render() {
        return (
            <div className="presentation-page index-page">
                <div className="wrapper">
                    <div className="page-header header-filter">
                        <BackgroundImages/>
                        <div className="page-header-image" data-parallax="true"
                             style={{"backgroundImage": "url('../img/norwiegan-fiord.png')"}}></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 ml-auto mr-auto text-center">
                                    <h1 className="h1-seo">Qapla•
                                        <small className="text-warning">PRO Software</small>
                                    </h1>
                                    <h3>
                                        Where solutions succeed
                                    </h3>
                                    <a href="/contact-us" className="btn btn-warning btn-simple btn-lg mt-4">
                                        Contact us
                                    </a>
                                </div>
                            </div>
                            <div className="row mt-5 mb-5 d-flex justify-content-center">
                                <iframe width="640" height="360"
                                        src="https://www.youtube.com/embed/bT2GN4EjSFU?controls=0&modestbranding=1&rel=0"
                                        title="Qapla Promotional Video" frameBorder="0"
                                        style={{
                                            "border": "2px solid #435DB5",
                                            "borderRadius": "calc(0.2857rem - 0.0625rem) calc(0.2857rem - 0.0625rem) 0 0"
                                        }}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="section no-bottom-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 ml-auto mr-auto">
                                    <h3 className="title">Qapla: Igniting Digital Innovation, Crafting Your Future</h3>
                                    <p>
                                        Welcome to <b>Qapla</b>, where innovation and creativity come together to make
                                        your
                                        vision a reality.
                                        As a leader in custom software development, we pride ourselves on creating
                                        bespoke solutions that
                                        meet the unique needs of our customers. Our expertise spans the digital
                                        environment, from designing
                                        stunning websites and sophisticated installed applications, to developing
                                        cutting-edge cloud software
                                        and <a target="_blank" rel="noopener noreferrer"
                                               href="https://apps.apple.com/au/app/swot-zone/id6443672500">iOS</a>
                                        and <a target="_blank" rel="noopener noreferrer"
                                               href="https://play.google.com/store/apps/details?id=zone.swot">Android</a>
                                        applications. At Qapla, we don't just deliver products, we deliver experiences
                                        that exceed expectations and redefine
                                        your perception of technology.
                                    </p>
                                    <br/>
                                    <p>
                                        Our passion for excellence has driven us to develop high-throughput software
                                        solutions that enable businesses and individuals to thrive in the rapidly
                                        evolving digital world. We are known for our <a target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                        href="https://www.blivakker.no">end-to-end
                                        eCommerce solutions</a>,
                                        and for developing a breakthrough AI-powered, teacher-led <a target="_blank"
                                                                                                     rel="noopener noreferrer"
                                                                                                     href="https://swot.zone">online
                                        education platform</a>, that is revolutionizing the learning experience. In
                                        addition to video
                                        chat applications, <a target="_blank" rel="noopener noreferrer"
                                                              href="https://browserboard.com">https://browserboard.com</a> demonstrates
                                        our commitment to providing
                                        versatile and easy-to-use tools for modern communication and collaboration. With
                                        Qapla, you will not only stay ahead of your competitors, but you become the
                                        curve.
                                    </p>
                                    <br/>
                                    <p className="blockquote">
                                        “Seize the time... Live now! Make now always the most precious time. Now will
                                        never come again.”
                                        <br/>
                                        <br/>
                                        <small>
                                            Captain Jean-Luc Picard
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section no-margin-padding">
                        <div className="container">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 ml-auto mr-auto">
                                        <div className="card card-blog card-plain blog-horizontal">
                                            <div className="row">
                                                <div className="col-lg-4 center-card-image">
                                                    <div className="card-image">
                                                        <a target="_blank" href="https://blivakk.no">
                                                            <img className="img rounded" src="../img/blivakker.png"
                                                                 alt="Screen shot of the Blivakker website"/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-8">
                                                    <div className="card-body">
                                                        <h3 className="card-title">
                                                            <a target="_blank"
                                                               href="https://www.blivakker.no/">BliVakker.no</a>
                                                        </h3>
                                                        <div className="card-description">
                                                            <p>
                                                                Introducing Blivakker.no, a remarkable eCommerce
                                                                platform that exemplifies the synergy of form and
                                                                function. At Qapla, we are proud to have contributed to
                                                                the development of Blivakker.no's full-stack software,
                                                                crafting a seamless user experience from front-end to
                                                                warehousing. Our expertise in bespoke software solutions
                                                                has enabled us to create an intuitive, visually
                                                                appealing interface that captivates customers while
                                                                providing them with a seamless browsing and purchasing
                                                                experience. Behind the scenes, our robust warehousing
                                                                and logistics software ensures that every aspect of the
                                                                order fulfillment process runs like a well-oiled
                                                                machine. Blivakker.no stands as a testament to Qapla's
                                                                dedication to delivering excellence in every project we
                                                                undertake, pushing the boundaries of what's possible in
                                                                eCommerce technology.
                                                            </p>
                                                            <div className="author">
                                                                <a target="_blank" href="https://www.blivakker.no/">
                                                                    <img
                                                                        src="https://www.blivakker.no/assets/images/touch/apple-touch-icon-blivakker-144.png"
                                                                        alt="blivakker.no"
                                                                        className="avatar img-raised"/>
                                                                    <div className="text">
                                                                        <span className="name">BliVakker.no</span>
                                                                        <div className="meta">Check out our work</div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="card card-blog card-plain blog-horizontal">
                                            <div className="row">
                                                <div className="col-lg-4 center-card-image">
                                                    <div className="card-image">
                                                        <a href="https://swot.zone">
                                                            <img className="img rounded" src="../img/swot.png"
                                                                 alt="a screenshot of the swot website"/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-8">
                                                    <div className="card-body">
                                                        <h3 className="card-title">
                                                            <a target="_blank" href="https://swot.zone">SWOT.zone</a>
                                                        </h3>
                                                        <div className="card-description">
                                                            <p>
                                                                Discover the future of education with <a target="_blank"
                                                                                                         href="https://swot.zone">https://swot.zone</a>,
                                                                an innovative online tutoring platform designed to
                                                                elevate the learning experience to new heights. SWOT
                                                                offers a comprehensive suite of features, including
                                                                video calls, interactive whiteboards, and collaborative
                                                                documents, all accessible through user-friendly Web, iOS
                                                                and Android applications. These cutting-edge tools
                                                                foster engaging and effective learning experiences,
                                                                breaking down barriers and empowering students to reach
                                                                their full potential. What truly sets SWOT apart is its
                                                                AI-assisted tutoring, harnessing the power of artificial
                                                                intelligence to provide personalized guidance and
                                                                support for each learner. With SWOT, students not only
                                                                receive access to an extensive network of professional
                                                                tutors but also benefit from the fusion of human
                                                                expertise and advanced technology, paving the way for a
                                                                new era of academic success.
                                                            </p>
                                                            <div className="author">
                                                                <a target="_blank" href="https://swot.zone">
                                                                    <img src="../img/swot-logo.png" alt="swot.zone logo"
                                                                         className="avatar img-raised"/>
                                                                    <div className="text">
                                                                        <span className="name">SWOT.zone</span>
                                                                        <div className="meta">Check out our work</div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card card-blog card-plain blog-horizontal">
                                            <div className="row">
                                                <div className="col-lg-4 center-card-image">
                                                    <div className="card-image">
                                                        <a href="https://browserboard.com">
                                                            <img className="img rounded" src="../img/bb.png"/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-8">
                                                    <div className="card-body">
                                                        <h3 className="card-title">
                                                            <a target="_blank"
                                                               href="https://www.browserboard.com">Browserboard</a>
                                                        </h3>
                                                        <div className="card-description">
                                                            <p>
                                                                Introducing Browserboard.com, the ultimate online
                                                                collaborative whiteboard that fosters creativity and
                                                                teamwork in the digital age. Designed to bring people
                                                                together, Browserboard.com offers a highly intuitive and
                                                                versatile platform for ideation, brainstorming, and
                                                                problem-solving. With the added advantage of API access,
                                                                you can effortlessly embed Browserboard.com within your
                                                                own applications, providing seamless integration and
                                                                enhancing the user experience. Whether you're working on
                                                                a design project, fine-tuning a business strategy, or
                                                                teaching a class, Browserboard.com's dynamic platform
                                                                empowers you to visualize ideas, share insights, and
                                                                collaborate in real-time, breaking down barriers and
                                                                elevating the way we connect, communicate, and create.
                                                            </p>
                                                            <div className="author">
                                                                <a target="_blank" href="https://www.browserboard.com/">
                                                                    <img src="../img/bb-icon.png"
                                                                         alt="browserboard logo"
                                                                         className="avatar img-raised"/>
                                                                    <div className="text">
                                                                        <span className="name">browserboard.com</span>
                                                                        <div className="meta">Check out our work</div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section section-blog-info">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 ml-auto mr-auto">
                                        <div className="row">
                                            <div className="col-md-8 ml-auto mr-auto">
                                                <div className="card card-profile profile-bg">
                                                    <div className="card-header"
                                                         style={{"backgroundImage": "url('../img/norwiegan-mountain.jpg')"}}>
                                                        <div className="card-avatar">
                                                            <a href="#pablo">
                                                                <img className="img img-raised"
                                                                     src="../img/73342324_10157789158313060_5551936182943744000_n.jpg"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <h3 className="card-title">Greg Randall</h3>
                                                        <h6 className="category text-primary">Director</h6>
                                                        <p className="card-description">
                                                            Meet Greg, the visionary director at the helm of our dynamic
                                                            team, whose unwavering passion for innovation and excellence
                                                            drives the success of our company. With a wealth of
                                                            experience in the technology sector, Greg possesses an
                                                            unparalleled understanding of the ever-evolving digital
                                                            landscape. He skillfully navigates the complexities of the
                                                            industry, inspiring his team to push boundaries and redefine
                                                            the status quo. As a natural leader, Greg fosters a
                                                            collaborative and supportive environment, empowering each
                                                            team member to reach their full potential and contribute to
                                                            the collective growth of the company. His dedication to
                                                            delivering bespoke solutions and outstanding customer
                                                            experiences is a testament to his commitment to not only
                                                            meeting but surpassing client expectations, solidifying his
                                                            reputation as a forward-thinking pioneer in the world of
                                                            technology.
                                                        </p>
                                                    </div>
                                                    <div className="card-footer">
                                                        <div className="follow ">
                                                            <a className="btn btn-primary btn-sm"
                                                               href="/contact-us">Contact</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

import React, {Component} from 'react';

export class BackgroundImages extends Component {
    render() {
        return (
            <>
                <div className="squares square1"></div>
                <div className="squares square2"></div>
                <div className="squares square3"></div>
                <div className="squares square4"></div>
                <div className="squares square5"></div>
                <div className="squares square6"></div>
                <div className="squares square7"></div>
                <img src="img/blob.png" className="path" alt="x"/>
                <img src="img/path2.png" className="path2" alt="x"/>
                <img src="img/triunghiuri.png" className="shapes triangle" alt="x"/>
                <img src="img/waves.png" className="shapes wave" alt="x"/>
                <img src="img/cercuri.png" className="shapes circle" alt="x"/>
            </>
        );
    }
}
